<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
                :to="{ path: '/system_Management/system_management_home' }"
                >系统管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>日志信息</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">日志筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="120px"
                ref="queryFormRef"
            >
                <el-row :gutter="10">
                    <el-col :span="6">
                        <el-form-item label="创建人" prop="userName">
                            <el-input
                                v-model="queryForm.userName"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="请求地址" prop="url">
                            <el-input
                                v-model="queryForm.url"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="终端" prop="terminal">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.terminal"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_terminal"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="起止时间" prop="dateTimeRange">
              <el-date-picker
              style="width: 100%;"
                v-model="queryForm.dateTimeRange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
                clearable
              >
              </el-date-picker>
            </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" type="flex" justify="end">
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="getAllLogsList('search')"
                            >查询</el-button
                        >
                    </el-col>
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            plain
                            style="width: 100%"
                            @click="reset"
                            >重置</el-button
                        >
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- 日志列表 -->
        <el-card class="list_card" style="margin-top: 20px">
            <el-row class="title_row">
                <el-col :span="12">
                    <div class="list_title title_class">日志列表</div>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column
                    label="创建人"
                    prop="creator"
                ></el-table-column>
                <el-table-column label="浏览器" prop="browser"></el-table-column>
                <el-table-column
                    label="请求IP"
                    prop="ipAdd"
                ></el-table-column>
                <el-table-column
                    label="请求方式"
                    prop="method"
                ></el-table-column>
                <el-table-column
                    label="请求方法"
                    prop="signature"
                    :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                    label="请求参数"
                    prop="argsParam"
                    :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column label="请求URL" prop="url"></el-table-column>
                <el-table-column
                    label="操作地址"
                    prop="operatingSystem"
                ></el-table-column>
                <el-table-column label="发起时间" prop="createTime"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import { getAllLogsList } from '@/http/api'
export default {
  data () {
    return {
      dict_terminal: this.$userInfo.getDataList(this, 'dict_terminal').then(value => { this.dict_terminal = value }),
      dataList: [],
      dateTimeRange: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0
    }
  },
  created () {
    this.initTime()
  },
  methods: {
    initTime () {
      var endTimeDate = new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' 10:00:00')
      var endTimeStamp = endTimeDate.getTime()
      var startTimeStamp = endTimeStamp - 24 * 60 * 60 * 1000
      this.queryForm.beginTime = startTimeStamp
      this.queryForm.endTime = endTimeStamp
      this.queryForm.dateTimeRange = [startTimeStamp, endTimeStamp]
      this.getAllLogsList()
    },
    getAllLogsList (type) {
      if (type === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      this.queryForm.beginTime = this.dateTimeRange[0]
      this.queryForm.endTime = this.dateTimeRange[1]
      getAllLogsList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getAllLogsList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getAllLogsList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getAllLogsList()
    },
    orderTimeChange () {
    }
  }
}
</script>

<style scoped>
.a-btn {
    color: #409eff;
    font-size: 14px;
}
.row {
    display: flex;
    margin-bottom: 12px;
}
</style>
